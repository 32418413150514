import React from "react";

const Resume = ({ id, title }) => {
  return (
    <div className="bg_dark_blue" id={id}>
      <div className="">
        <div className="heading">
          <h4 className="main_heading">{title}</h4>
          <h2 className="sub_heading hidden sm:block">RESUME</h2>
        </div>
      </div>

      <div className="px-4  md:px-8 lg:px-16 xl:px-0 xl:w-[1220px] xl:mx-auto pt-10 pb-24 sm:flex  resume">
        <div className="sm:w-[50%]">
          <div className="flex items-center heading">
            <i class="fa-solid fa-graduation-cap"></i>
            <h1 className="text-white ">Education</h1>
          </div>

          <div className="side">
            <div className="degree " data-aos="fade-up">
              <h2>Bachelor of Computer Science</h2>
              <h3>2023-Present</h3>
              <b>Virtual University</b>
            </div>

            <div className="degree " data-aos="fade-up">
              <h2>CA 1st Level</h2>
              <h3>2020-2022</h3>
              <b>Tips Collage</b>
            </div>

            <div className="degree1" data-aos="fade-up">
              <h2>Intermediate</h2>
              <h3>2018-2020</h3>
              <b>Kips Collage</b>
            </div>
          </div>
        </div>

        <div className="sm:w-[50%] sm:mt-0 mt-12">
          <div className="flex items-center heading ">
            <i class="fa-solid fa-briefcase"></i>
            <h1 className="text-white ">Experience</h1>
          </div>

          <div className="side">
            <div className="degree " data-aos="fade-up">
              <h2>MERN Stack Developer</h2>
              <h3>September 2024 - Present</h3>
              <b>Level UP Marketplace</b>
              <p>
                Mastering advanced techniques in MongoDB, Express.js, React.js, Next.js, and Node.js, building high-performance, scalable web applications with the MEAN stack, one of the most in-demand tech stacks today.
              </p>
            </div>

            <div className="degree1 " data-aos="fade-up">
              <h2>React Js Developer</h2>
              <h3>September 2023 - September 2024</h3>
              <b>Riemann System</b>
              <p>
                Experienced React JS developer proficient in crafting innovative
                web applications, dedicated to cross-functional teamwork,
                delivering scalable and maintainable code, and staying current
                with cutting-edge technologies and best practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
